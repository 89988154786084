/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
/*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*/

export default {
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_ITEMS_TOTAL(state, total) {
    state.total = total;
  },
  SET_ITEMS_NUM_PAGES(state, num) {
    state.num_of_pages = num;
  }
};
